import './App.scss';
import { BrowserRouter as Router, Routes, Switch, Route, Link, Outlet } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Header from './components/header';
import Home from './pages/home';
import About from './pages/about';
import Case1 from './pages/case_study_1';
import Child from './pages/child';
import ReactDOM from 'react-dom/client';
import Meta from './components/meta_tags';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB9_s2LOCXzNBxV5nVQS5ZQsxHvu49NH7k",
  authDomain: "yasintha-s-portfolio.firebaseapp.com",
  projectId: "yasintha-s-portfolio",
  storageBucket: "yasintha-s-portfolio.appspot.com",
  messagingSenderId: "633091388818",
  appId: "1:633091388818:web:80ff15bc80e3825f5c7d4b",
  measurementId: "G-2Q2WJCZRWY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App(){

  return (
      <Router>
      <Header />
      <div>
        <Routes >
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/case1" element={<Case1 />} />
          <Route path='/model/:id' element={<Model />} />
        </Routes>
      </div>
    </Router>
  );
}



export default App;


function Model() {
  return (
    <div>
      <h1>Yasmeeen</h1>
      <Outlet />
    </div>
  );
}
function Model2() {
  return (
    <div>
      <h1>Yasin</h1>
    </div>
  );
}