import React from "react";

//images
import LinkedInIcon from './../images/social-media/linkedin-fill 1.svg';
import TwitterIcon from './../images/social-media/twitter-fill 1.svg';
import InstagramIcon from './../images/social-media/instagram-line 1.svg';
import BehanceIcon from './../images/social-media/behance-fill 1.svg';
import DribbleeIcon from './../images/social-media/dribbble-line 1.svg';
//styles
import './../styles/footer.scss';

const Footer = () => {

    // open in new tab
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }


    return (
        <footer>
            <div className="container">
                <p>need a designer?</p>
                <h4>let's work together!</h4>
                <p>bestofyasi@gmail.com</p>
            </div>
            <div className="bottom">
                <div className="copy-right">© 2022 Yasintha Perera, All rights reserved.</div>
                <div className="social-media">
                    <p>you can find me here</p>
                    <div className="icons">
                        <img src={LinkedInIcon} alt="LinkedIn Yasintha" onClick={() => openInNewTab('https://www.linkedin.com/in/yasintha/')} />
                        <img src={TwitterIcon} alt="Twitter Yasintha" onClick={() => openInNewTab('https://twitter.com/whyPerera')} />
                        <img src={InstagramIcon} alt="Instagram Yasintha" onClick={() => openInNewTab('https://instagram.com/bestofyasi')} />
                        <img src={BehanceIcon} alt="Behance Yasintha" onClick={() => openInNewTab('https://www.behance.net/yasintha/')} />
                        <img src={DribbleeIcon} alt="Dribbble Yasintha" onClick={() => openInNewTab('https://www.dribbble.com/yasintha/')} />
                    </div>
                </div>
            </div>
        </footer>

    );
};

export default Footer;