import React from "react";
import { Link } from "react-router-dom";
import YasinthaLogo from './../images/logo-yasintha.svg';


//styles
import './../styles/header.scss';

const Header = () => {
  return (
    <nav>
        <div className="logo">
          <Link to="/">
            <img src={YasinthaLogo} />
          </Link>
        </div>

        <div className="nav-items">
          <ul>
            <li>
              <Link to="/work">Work</Link>
            </li>
            <li>
              <Link to="/explorations">Explorations</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            {/* <li>
      <Link to={`/model/yasmeen-tariq`}>yasmeen</Link>
    </li>
    <li>
      <Link to={`/model/yasintha`}>Yasi</Link>
    </li> */}
          </ul>
        </div>

      </nav>

  );
};

export default Header;
