import React from "react";
import { Link } from "react-router-dom";

//images
import Me1 from './../images/about-yasintha.png';
//styles
import './../styles/about.scss';

const About = () => {
  return (
   <div className="about">
    <div className="welcome">Yo!</div>
    <div className="intro description">My name is Yasintha Perera. Before I discovered UI/UX design, I was a s/w engineer. I left everything behind and moved to my favorite country and pursue my dream of creating meaningful and delightful products for companies I admire.
    <br /><br />To me, User Experience Design means making things easier (and fun, when appropriate) for people to achieve their tasks. My favorite example of good UX is when I order food through an app and I can see in a little map when my food will arrive. <span className="text-bold">#mindblown</span></div>
    <img src={Me1} alt="" />
   </div>

  );
};

export default About;