import * as React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import ProgressiveImage from "react-progressive-image";
import Image from './../images/hero-img.png';
import ImagePlaceHolder from './../images/hero-img-placeholder.png';
import { HashLink as LinkA } from 'react-router-hash-link';
import Works from "../components/works";

import './../styles/home.scss';
import 'animate.css';
import Footer from "../components/footer";


const Home = () => {
    return (
        <>
            <div className="container">

                {/* <ProgressiveImage
                    src={Image}
                    placeholder={ImagePlaceHolder}>
                    {(src) => (
                        <motion.img className="thumbail"
                            src={src}
                            alt='Yasmeen Tariq'

                        />
                    )}
                </ProgressiveImage> */}
                <motion.div

                    className="hero">
                    {/* <p> Yasintha Perera - UI/UX Designer</p> */}
                    <motion.h1  // initial={{ scale:1 }}
                        animate={{ scale: [1.6, 1],}}
                        className="title">Humanize the design</motion.h1>
                    <motion.p
                        animate={{
                            y: [90, 0],
                            opacity: [0, 1]
                        }}
                        transition={{ delay: .1 }}

                        className="body">A UX Designer based in <span className="text-line-through">Sri Lanka</span> Singapore. I'm passionate about working on complex problems and crafting joyful environments with holistic thinking.
                    </motion.p>
                    <motion.div
                     animate={{
                        delay: 10,
                        y: [100, 15],
                        opacity: [0, 1]
                    }}
                    transition={{ delay: .2 }}>
                   
                        <LinkA className="cta" to="#yourAnchorTag">See my work</LinkA>
                    </motion.div>
                    <ProgressiveImage
                        src={Image}
                        placeholder={ImagePlaceHolder}>
                        {(src) => (
                            <motion.img className="hero-img"
                                src={src}
                                alt='Yasintha Perera | UI/UX Designer'

                            />
                        )}
                    </ProgressiveImage>
                </motion.div>
            </div>
             <section id="yourAnchorTag" className="work">
                 <Works />

             </section> 

             <Footer/>
        </>
    );
};
export default Home;
