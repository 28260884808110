import gsap from "gsap/all";
import React from "react";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Card1 from './../images/card 1.png';
import Card2 from './../images/card 2.png';
import Card3 from './../images/card 3.png';
import Card4 from './../images/card 4.png';
import Card5 from './../images/card 5.png';




//styles
import './../styles/works.scss';

const Works = () => {
  return (
    // <div className="works">
    //   <h3>Nortable Works</h3>
    //   <p className="description">Collection of great projects made with love</p>

    //   <div className="selected-work-card">
    //     <div className="project-name">Project name</div>
    //     <div className="title">What is the case study  in few lines</div>
    //     <div className="link-project">View case study</div>
    //     <img src="" alt="" />
    //   </div>
    //   <div className="selected-work-card">
    //     <div className="project-name">Project name</div>
    //     <div className="title">What is the case study  in few lines</div>
    //     <div className="link-project">View case study</div>
    //     <img src="" alt="" />
    //   </div>
    //   <div className="selected-work-card">
    //     <div className="project-name">Project name</div>
    //     <div className="title">What is the case study  in few lines</div>
    //     <div className="link-project">View case study</div>
    //     <img src="" alt="" />
    //   </div>
    //   <div className="selected-work-card">
    //     <div className="project-name">Project name</div>
    //     <div className="title">What is the case study  in few lines</div>
    //     <div className="link-project">View case study</div>
    //     <img src="" alt="" />
    //   </div>


    // </div> 
    <div className="container-work">
      <h3>Nortable Works</h3>
      <p className="description">Collection of great projects made with love</p>
      
      <div className="cards">
        <div className="card-1">
         <img src={Card1} alt="" />
        </div>
        <div className="card-2">
           <img src={Card2} alt="" />
        </div>
        <div className="card-3">
           <img src={Card3} alt="" />
        </div>
        <div className="card-4">
           <img src={Card4} alt="" />
        </div>
        <div className="card-5">
           <img src={Card5} alt="" />
        </div>
      </div>
    </div>


  );
};

export default Works;