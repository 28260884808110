import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/footer";


//styles
import './../styles/case_study.scss';

//images
import Cover from './../images/case-study-1/cover.png';
import Lap from './../images/case-study-1/lap1.png';
import Phone from './../images/case-study-1/phone.png';
import Mac from './../images/case-study-1/mac.jpeg';


const Case1 = () => {
  return (
    <div className="container-case-study">
      <div className="heading">
        <p className="project-name">onnit</p>
        <h2 className="project-short-description">Enabling accountability in goals for college students</h2>
      </div>
      <img className="cover-img" src={Cover} alt="" />

      <div className="container-980">

        <div className="properties">
          <p className="property">Introduction</p>
          <p className="value">Roo-ulette is an imagined feature for Deliveroo (food delivery app) that facilitates picky eaters in deciding which vendor to order from. <span className="highlight-bold">By disrupting the 'endless scrolling',</span>  we relieved the frustration of decision paralysis and effectively reduced the time needed to place an order via the app.</p>
        </div>
        <div className="properties">
          <p className="property">Timeline</p>
          <p className="value">September - December 2020 (12 weeks)</p>
        </div>

        <div className="properties">
          <p className="property">2 column property</p>
          <div className="col-2">
            <p className="value">User Research, UX design: Wireframing, Prototyping, UI design + Iterations</p>
            <p className="value">User Research, UX design: Wireframing, Prototyping, UI design + Iterations</p>
          </div>
        </div>

        <div className="divider"></div>

        <div className="properties">
          <p className="property">Problem</p>
          <p className="value"><span className="highlight-large">College students fail to accomplish their personal goals.</span> As a college student, I’ve noticed how many of my peers I were able to persevere towards our academic pursuits. Yet, we have failed to accomplish our personal goals. This sparked the question- why was there this disconnect in our lives? </p>
        </div>
        <div className="properties">
          <p className="property">Solution</p>
          <p className="value">Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste, soluta rerum nemo neque illo aut. Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste, soluta rerum nemo neque illo aut.Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste, soluta rerum nemo neque illo aut.Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste, soluta rerum nemo neque illo aut.</p>
        </div>
        <div className="properties">
          <p className="property"> </p>

          <img src={Mac} alt="" className="image-value" />
        </div>

        {/* <img src={Cover} alt="" className="image-980" /> */}
        {/* <img src={Lap} alt="" className="image-980" /> */}
      </div>
      <img src={Cover} alt="" className="image-full-width" />
      <img src={Phone} alt="" className="image-auto-width" />
      <Footer/>
    </div>


  );
};

export default Case1;